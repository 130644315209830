<template>
    <div class="settle">
        <el-card>
            <template #header>
                <div class="clearfix" style="text-align:left">
                    <span>结算明细</span>
                </div>
            </template>
            <div>
                <div class="content-header">
                    <el-row :gutter="5">
                        <el-col :span="24"   style="text-align:left;">
                            <div class="searchblock">
                                
                                
                                <el-date-picker v-model="range" type="datetimerange" unlink-panels range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" :shortcuts="shortcuts" style="margin-right:10px;" size="small" :default-time="defaulttime"></el-date-picker>
                                <el-input v-model="keyword"  placeholder="请输入流水号/订单号" prefix-icon="el-icon-search" class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
                                <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down" size="small">搜索</el-button>
                                <el-button type="success" @click="add" style="margin-left:10px;" class="hidden-sm-and-down" size="small" >一键申请发票</el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                
                <el-table :data="tabledata" border size="small" style="margin-top:20px;">
                    <el-table-column type="index" label="序号" align="center"></el-table-column>
                    <el-table-column label="原支付金额" prop="totalmoney">
                        <template #default="scope">
                            ¥{{ scope.row.totalmoney }}元
                        </template>
                    </el-table-column>
                    <el-table-column label="结算金额" prop="Amount">
                        <template #default='scope'>
                            <span v-if="scope.row.Amount >= 0">¥{{scope.row.Amount}}元</span>
                             <span v-if="scope.row.Amount < 0" style="color:#ff0000">¥{{scope.row.Amount}}元</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="可开票金额" prop="baoxiao">
                        <template #default="scope">
                            ¥{{ scope.row.baoxiao }}元
                        </template>
                    </el-table-column>
                    
                    <el-table-column label="支付流水" prop="TradeNo"></el-table-column>
                    <el-table-column label="订单编号" prop="OrderUID"></el-table-column>
                    <el-table-column label="期数" prop="PeriodNo"></el-table-column>
                    <el-table-column label="开票状态" prop="Invoice">
                        <template #default="scope">
                            <span v-if="scope.row.Invoice == 1">未开票</span>
                            <span v-else>已开票</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="结算比例" prop="Rate"></el-table-column>
                    <el-table-column label="结算时间" prop="AddDTime">
                        <template #default='scope'>
                            {{formatDate(scope.row.AddDTime)}}
                        </template>
                    </el-table-column>
                    <el-table-column label="备注" prop="Remark"></el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                :page-sizes="[10,20,30,50,100]"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
            </div>
        </el-card>
    </div>
    <el-dialog v-model="addsettle" title="申请发票" width="30%" center>
        
        <el-form :model="settleinfo" label-position="right" ref="settleinfo"  label-width="160px" :rules="rules">
                <div class="info" style="margin-bottom:19px">
                    <div style="display:flex;flex-direction: column;justify-content: center;align-items: flex-start;padding: 0 7%;">
                        <strong style="color:#ff0000">提示： 1、每次开票金额不能少于1000元。2、开票仅限为6个月内付款的流水，超过6个月不支持开票</strong>
                    </div>
                </div>
                <el-form-item label="本次申请的可开票金额:">
                    ¥{{ settleinfo.money }}元
                </el-form-item>
                <el-form-item label="发票类型:"  prop="invoice">
                    <el-radio-group v-model="settleinfo.invoice" class="ml-4">
                        <el-radio label="1" size="large">电子普票</el-radio>
                        <el-radio label="2" size="large">电子专票</el-radio>
                    </el-radio-group>
                </el-form-item>
                
                <el-form-item label="公司名称:" prop="companyname">
                    <el-input v-model="settleinfo.companyname" style="width:250px;"></el-input>
                </el-form-item>
                <el-form-item label="纳税人识别号:" prop="taxpayer">
                    <el-input v-model="settleinfo.taxpayer" style="width:250px;"></el-input>
                </el-form-item>
                <el-form-item label="电子邮箱:"  prop="email">
                    <el-input  style="width:250px;" v-model="settleinfo.email"></el-input>
                </el-form-item>
        </el-form>
        
        <template #footer>
            <el-button size="small" @click="Save" type="primary">确定</el-button>
            <el-button size="small" @click="Cancel">取消</el-button>
        </template>
    </el-dialog>
</template>
<script>
import qs from 'qs'
import { ElMessage } from 'element-plus';
import Cookies from 'js-cookie'
import constant from '@/constant'
import { reactive } from 'vue'
export default {
    data(){
        return {
            tabledata:[],
            ismerchant:'',
            mid:'',
            addsettle:false,
            keyword:'',
            range:'',
            merchantid:'',
            curpage:1,
            pagesize:10,
            totalcount:'',
            settleinfo:reactive({
                email:"",
                ids:"",
                companyname:"",
                taxpayer:"",
                invoice:"1",
                money:"",
                mid:""
            }),
            rules:{
                companyname:[{
                    required:true,
                    message:'公司名称不能为空',
                    trigger:'blur'
                }],
                taxpayer:[{
                    required:true,
                    message:'纳税人识别号',
                    trigger:'blur'
                }],
                email:[{
                    required:true,
                    message:'请填写正确的邮箱格式',
                    trigger:'blur'
                }]
            },
            type:'',
            
        }
    },
    methods:{
        handleCurrentChange(val){
            console.log(val);
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val){
            console.log(val);
            this.pagesize = val;
            this.init();
        },
        Save(){
            this.$refs['settleinfo'].validate((valid)=>{
                if(valid){
                    console.log(this.settleinfo);
                    var regex = /^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+(com|cn|net|org)$/
                    var isok = regex.test(this.settleinfo.email);
                    if(!isok){
                        ElMessage({
                            type:"error",
                            message:"请填写正确的邮箱格式",
                        })
                        return false
                    }
                    this.axios.post(constant.invoiceapply,qs.stringify(this.settleinfo),{
                        headers:{
                            "content-type":"application/x-www-form-urlencoded"
                        }
                    }).then((response)=>{
                        console.log(response.data);
                        if(response.data.code == "200"){
                            this.$message.success("申请成功");
                            this.addsettle = false;
                        }else{
                            this.$message.error("申请失败");
                        }
                    });
                }else{
                    return false;
                }
            });
        },
        Cancel(){
            this.addsettle = false;
        },
        add(){
            this.axios.get(constant.getinvoice,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                   mid:this.mid 
                }
            }).then((response)=>{
                console.log(response.data)
                if(response.data.money != 0){
                    this.addsettle = true
                    this.settleinfo.money = response.data.money
                    this.settleinfo.ids = response.data.id
                    this.settleinfo.mid = this.mid
                }else{
                    ElMessage({
                        type:"error",
                        message:"暂时无发票可开"
                    })
                }
            })
        },
        init(){
            console.log(this.mid)
            this.axios.get(constant.invoice,{
                headers:{
                    "content-type":"application/json"
                },
                params:{
                    keyword:this.keyword,
                    merchantid:this.merchantid,
                    type:this.type,
                    range:this.range,
                    curpage:this.curpage,
                    pagesize:this.pagesize,
                    ismerchant:this.ismerchant,
                    mid:this.mid,
                }
            }).then((response)=>{
                console.log(response.data);
                this.tabledata = response.data.list;
                this.totalcount = response.data.totalcount*1;
                this.curpage = response.data.curpage*1;
                this.total = response.data.total*1;
            });
        },
        formatDate(date){
            var datetime = new Date(date*1000);
            var year = datetime.getFullYear();
            var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
            var day = ("0" + datetime.getDate()).slice(-2);
            var hour = ("0" + datetime.getHours()).slice(-2);
            var minute = ("0" + datetime.getMinutes()).slice(-2); 
            var second = ("0" + datetime.getSeconds()).slice(-2); 
            return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
        },
    },
    created:function(){
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("id");
        console.log(this.ismerchant)
        this.axios.get(constant.allmerchant,{
            headers:{
                "content-type":"application/json"
            }
        }).then((response)=>{
            console.log(response.data);
            this.merchants = response.data;
        });
        this.init();
    }
}
</script>
<style scoped>
.tag-form-item {
    margin-bottom: 0;
}
.info {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>